const UserList = () => import("@/views/user/UserList.vue");
const UserChange = () => import("@/views/user/UserChange.vue");


const user_routes = [
    {
        path: '',
        name: 'UserList',
        component: UserList,
        props: route => ({params: route.query})
    },
    // {
    //     path: 'add',
    //     name: 'UserCreate',
    //     component: UserChange,
    //     props: route => ({ params: route.query, add: true })
    // },
    {
        path: ':pk',
        name: 'UserUpdate',
        component: UserChange,
        props: route => ({params: route.query, pk: route.params.pk})
    }
]
export default user_routes;