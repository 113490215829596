const TagList = () => import("@/views/misc/tag/TagList.vue");
const TagChange = () => import("@/views/misc/tag/TagChange.vue");

const tag_routes = [
    {
        path: '',
        name: 'TagList',
        component: TagList,
        props: route => ({ params: route.query })
    },
    {
        path: 'add',
        name: 'TagCreate',
        component: TagChange,
        props: route => ({ params: route.query, add: true })
    },
    {
        path: ':pk',
        name: 'TagUpdate',
        component: TagChange,
        props: route => ({ params: route.query, pk: route.params.pk })
    },
]
export default tag_routes;