const UploadList = () => import("@/views/misc/upload/UploadList.vue");
const UploadChange = () => import("@/views/misc/upload/UploadChange.vue");

const upload_routes = [
    {
        path: '',
        name: 'UploadList',
        component: UploadList,
        props: route => ({ params: route.query })
    },
    {
        path: 'add',
        name: 'UploadCreate',
        component: UploadChange,
        props: route => ({ params: route.query, add: true })
    },
    {
        path: ':pk',
        name: 'UploadUpdate',
        component: UploadChange,
        props: route => ({ params: route.query, pk: route.params.pk })
    },
]
export default upload_routes;