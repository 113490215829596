import Toast from "bootstrap/js/dist/toast.js";
import Tooltip from "bootstrap/js/dist/tooltip";

export const childDirective = {
    beforeMount(el, binding) {
        const {value, arg, modifiers} = binding;
        const child = value[arg];
        const setup = () => {
            return {[arg]: child}
        }
        binding.instance.$.setupState = {...binding.instance.$.setupState, ...setup()}
    },
    mounted(el, binding) {
        el._prevTemplate = el.innerHTML
        el.innerHTML = `<template v-slot:default="props">
      <div v-bind="props">
        <div v-html="\`${el._prevTemplate}\`" v-bind="$with(props, ${binding.value.expression})"></div>
      </div>
    </template>`
    }
};
export const toastDirective = {
    mounted(el) {
        let toast = new Toast(el);
        toast.show();
    },
}

export const tooltipDirective = {
    mounted(el, binding) {
        if (!binding.value) return;
        let tooltip = new Tooltip(el, {
            placement: binding.arg || 'top',
            title: binding.value,
            html: true,
            container: '#app',
            delay: {show: 100, hide: 100}
        });
        el.__vueTooltip__ = tooltip;
    },
    unmounted(el) {
        if (el.__vueTooltip__) {
            el.__vueTooltip__.dispose(); // Dispose of the tooltip instance when the directive is unmounted
            delete el.__vueTooltip__; // Clean up the reference
        }
    }
}

export const indeterminateDirective = {
    mounted(el, binding) {
        el.indeterminate = Boolean(binding.value);
        const values = [false, true, 'none'];
        el.currentIndex = el.indeterminate ? 0 : (el.checked ? 2 : 1);
        el.addEventListener('change', () => {
            el.checked = values[el.currentIndex];
            el.indeterminate = (values[el.currentIndex] === 'none');
            el.currentIndex = (el.currentIndex + 1) % values.length;
        });
    },
}

export const autosizeTextArea = {
    mounted(el) {
        el.style.overflow = 'hidden'; // Prevent scrollbar
        el.style.resize = 'none'; // Disable resizing
        el.style.height = 'auto';
        el.style.height = `${el.scrollHeight - 5}px`; // Adjust height based on content

        el.addEventListener('input', () => {
            el.style.height = 'auto'; // Reset height
            el.style.height = `${el.scrollHeight - 5}px`; // Set height based on scrollHeight
        });
    },
    updated(el) {
        el.style.height = 'auto';
        el.style.height = `${el.scrollHeight - 5}px`; // Adjust height based on content
    }
};