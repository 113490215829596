const LocationList = () => import("@/views/misc/location/LocationList.vue");
const LocationChange = () => import("@/views/misc/location/LocationChange.vue");

const location_routes = [
    {
        path: '',
        name: 'LocationList',
        component: LocationList,
        props: route => ({ params: route.query })
    },
    {
        path: 'add',
        name: 'LocationCreate',
        component: LocationChange,
        props: route => ({ params: route.query, add: true })
    },
    {
        path: ':pk',
        name: 'LocationUpdate',
        component: LocationChange,
        props: route => ({ params: route.query, pk: route.params.pk })
    },
]
export default location_routes;