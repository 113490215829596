const BrandList = () => import("@/views/misc/brand/BrandList.vue");
const BrandChange = () => import("@/views/misc/brand/BrandChange.vue");


const brand_routes = [
    {
        path: '',
        name: 'BrandList',
        component: BrandList,
        props: route => ({ params: route.query })
    },
    {
        path: 'add',
        name: 'BrandCreate',
        component: BrandChange,
        props: route => ({ params: route.query, add: true })
    },
    {
        path: ':pk',
        name: 'BrandUpdate',
        component: BrandChange,
        props: route => ({ params: route.query, pk: route.params.pk })
    },
]
export default brand_routes;