const AttributeList = () => import("@/views/misc/attribute/AttributeList.vue");
const AttributeChange = () => import("@/views/misc/attribute/AttributeChange.vue");


const attribute_routes = [
    {
        path: '',
        name: 'AttributeList',
        component: AttributeList,
        props: route => ({ params: route.query })
    },
    {
        path: 'add',
        name: 'AttributeCreate',
        component: AttributeChange,
        props: route => ({ params: route.query, add: true })
    },
    {
        path: ':pk',
        name: 'AttributeUpdate',
        component: AttributeChange,
        props: route => ({ params: route.query, pk: route.params.pk })
    },
]
export default attribute_routes;