const AddressList = () => import("@/views/customer/address/AddressList.vue");
const AddressChange = () => import("@/views/customer/address/AddressChange.vue");


const address_routes = [
    {
        path: '',
        name: 'AddressList',
        component: AddressList,
        props: route => ({ params: route.query })
    },
    {
        path: 'add',
        name: 'AddressCreate',
        component: AddressChange,
        props: route => ({ params: route.query, add: true })
    },
    {
        path: ':pk',
        name: 'AddressUpdate',
        component: AddressChange,
        props: route => ({ params: route.query, pk: route.params.pk })
    },
]
export default address_routes;