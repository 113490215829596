const OptionList = () => import("@/views/misc/option/OptionList.vue");
const OptionChange = () => import("@/views/misc/option/OptionChange.vue");


const option_routes = [
    {
        path: '',
        name: 'OptionList',
        component: OptionList,
        props: route => ({ params: route.query })
    },
    {
        path: 'add',
        name: 'OptionCreate',
        component: OptionChange,
        props: route => ({ params: route.query, add: true })
    },
    {
        path: ':pk',
        name: 'OptionUpdate',
        component: OptionChange,
        props: route => ({ params: route.query, pk: route.params.pk })
    },
]
export default option_routes;