import booking_routes from "@/router/booking";
import sale_routes from "@/router/sale";

const OrderList = () => import("@/views/order/OrderList.vue");
const OrderChange = () => import("@/views/order/OrderChange.vue");


const order_routes = [
    {
        path: '',
        name: 'OrderList',
        component: OrderList,
        props: route => ({ params: route.query })
    },
    {
        path: 'add',
        name: 'OrderCreate',
        component: OrderChange,
        props: route => ({ params: route.query, add: true })
    },
    {
        path: 'booking',
        children: booking_routes
    },
    {
        path: 'sale',
        children: sale_routes
    },
    {
        path: ':pk',
        name: 'OrderUpdate',
        component: OrderChange,
        props: route => ({ params: route.query, pk: route.params.pk })
    },
]
export default order_routes;