<template>
  <div
      class="navbar navbar-dark sticky-top bg-dark flex-row align-items-start align-items-md-center p-0
       justify-content-start shadow">
    <a href="/"><img alt="Logo" class="navbar-brand me-0 px-3" src="@/assets/logoB.svg" style="height: 3rem;"></a>
    <button aria-controls="sidebarMenu" aria-expanded="true" aria-label="Toggle navigation"
            class="navbar-toggler position-absolute" data-bs-target="#sidebarMenu"
            data-bs-toggle="collapse"
            type="button">
      <span class="navbar-toggler-icon"></span>
    </button>
  </div>
</template>
<script setup>
</script>
<style scoped>

/*
 * Navbar
 */

.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
}

.navbar .navbar-toggler {
  top: .25rem;
  right: 1rem;
}
</style>