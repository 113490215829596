import {createApp} from 'vue'
import {createPinia} from 'pinia'

import App from './App.vue'
import router from './router'


/* Bootstrap */
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.js";

/* Bootstrap icons */
import 'bootstrap-icons/font/bootstrap-icons.css'

/* Custom CSS */
import './assets/main.css'

/* Custom JS */
import JsonViewer from 'vue-json-viewer'
import * as directives from "@/assets/directives";

/* VueDatePicker */
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

/* HIGHCHARTS */
import Highcharts from "highcharts";
import GanttModule from "highcharts/modules/gantt";
import draggablePoints from "highcharts/modules/draggable-points";
import HighchartsVue from "highcharts-vue";

GanttModule(Highcharts);
draggablePoints(Highcharts);
Highcharts.setOptions({
    accessibility: { enabled: false },
    lang: {
        // Main options
        months: [
            'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
            'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
        ],
        weekdays: [
            'Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'
        ],
        shortMonths: [
            'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
            'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'
        ],
        decimalPoint: ',',
        thousandsSep: '.',

        // Gantt-specific options
        loading: 'Cargando...',
        rangeSelectorFrom: "Desde",
        rangeSelectorTo: "Hasta",
        rangeSelectorZoom: "Zoom",

        // Buttons, context menu
        printChart: 'Imprimir gráfico',
        downloadPNG: 'Descargar imagen PNG',
        downloadJPEG: 'Descargar imagen JPEG',
        downloadPDF: 'Descargar documento PDF',
        downloadSVG: 'Descargar imagen SVG',

        // Tooltip and others
        noData: "No hay datos para mostrar",
        resetZoom: "Restablecer zoom",
        resetZoomTitle: "Restablecer zoom al nivel 1:1"
    }
});
// Override Date.prototype.toLocaleDateString to set default locale to 'es-ES'
const originalToLocaleDateString = Date.prototype.toLocaleDateString;
Date.prototype.toLocaleDateString = function (locale = 'en-CA', options) {
    return originalToLocaleDateString.call(this, locale, options);
};

// Override Date.prototype.toLocaleString to set default locale to 'es-ES'
const originalToLocaleString = Date.prototype.toLocaleString;
Date.prototype.toLocaleString = function (locale = 'en-CA', options) {
    return originalToLocaleString.call(this, locale, options);
};


//
// /* Internationalization */
// import {createI18n} from 'vue-i18n'
// import {messages} from './locale'
//
// const i18n = createI18n({
//   locale: 'es', // set locale
//   fallbackLocale: 'en', // set fallback locale
//   messages, // set locale messages
// })
//
const app = createApp(App)
app.directive('tooltip', directives.tooltipDirective)
app.directive('toast', directives.toastDirective)
app.directive('child', directives.childDirective)
app.directive('indet', directives.indeterminateDirective)
app.directive('autosize', directives.autosizeTextArea)
app.use(createPinia())
app.use(router)
app.use(JsonViewer)
app.use(HighchartsVue);
app.component('Datepicker', Datepicker);
// app.use(i18n)
app.mount('#app')
