const UnitStatusList = () => import("@/views/misc/unit-status/UnitStatusList.vue");
const UnitStatusChange = () => import("@/views/misc/unit-status/UnitStatusChange.vue");

const unit_status_routes = [
    {
        path: '',
        name: 'UnitStatusList',
        component: UnitStatusList,
        props: route => ({ params: route.query })
    },
    {
        path: 'add',
        name: 'UnitStatusCreate',
        component: UnitStatusChange,
        props: route => ({ params: route.query, add: true })
    },
    {
        path: ':pk',
        name: 'UnitStatusUpdate',
        component: UnitStatusChange,
        props: route => ({ params: route.query, pk: route.params.pk })
    },
]
export default unit_status_routes;