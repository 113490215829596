import brand_routes from "@/router/misc/brand";
import attribute_routes from "@/router/misc/attribute";
import option_routes from "@/router/misc/option";
import upload_routes from "@/router/misc/upload";
import tag_routes from "@/router/misc/tag";
import unit_status_routes from "@/router/misc/unit-status";
import unit_history_routes from "@/router/misc/unit-history";
import order_status_routes from "@/router/misc/order-status";
import location_routes from "@/router/misc/location";


const misc_routes = [
    { path: 'brand', children: brand_routes },
    { path: 'attribute', children: attribute_routes },
    { path: 'option', children: option_routes },
    { path: 'upload', children: upload_routes },
    { path: 'tag', children: tag_routes },
    { path: 'location', children: location_routes },
    { path: 'unit-status', children: unit_status_routes },
    { path: 'unit-history', children: unit_history_routes },
    { path: 'order-status', children: order_status_routes },
]
export default misc_routes;