const OrderStatusList = () => import("@/views/misc/order-status/OrderStatusList.vue");
const OrderStatusChange = () => import("@/views/misc/order-status/OrderStatusChange.vue");

const order_status_routes = [
    {
        path: '',
        name: 'OrderStatusList',
        component: OrderStatusList,
        props: route => ({ params: route.query })
    },
    {
        path: 'add',
        name: 'OrderStatusCreate',
        component: OrderStatusChange,
        props: route => ({ params: route.query, add: true })
    },
    {
        path: ':pk',
        name: 'OrderStatusUpdate',
        component: OrderStatusChange,
        props: route => ({ params: route.query, pk: route.params.pk })
    },
]
export default order_status_routes;