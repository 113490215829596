<template>
  <NavBar/>
  <div class="container-fluid viewport">
    <div class="row h-100">
      <div v-if="authStore.user" class="row h-100 overflow-auto col-auto">
        <SideBar/>
      </div>
      <div class="h-100 overflow-auto col px-0">
        <router-view/>
      </div>
    </div>
  </div>
  <Toasts/>
</template>

<script setup>
import {provide, ref} from "vue";
import NavBar from "@/components/NavBar.vue";
import SideBar from "@/components/SideBar.vue";
import {useAuthStore} from "@/stores";
import Toasts from "@/components/Toasts.vue";


const envs = import.meta.env
provide('envs', envs)
const authStore = ref(useAuthStore());
</script>

<style scoped>
.viewport {
  height: calc(100vh - 3rem);
}
</style>