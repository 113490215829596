const VariationList = () => import("@/views/variation/VariationList.vue");
const VariationChange = () => import("@/views/variation/VariationChange.vue");
import unit_routes from "@/router/unit";


const variation_routes = [
    {
        path: '',
        name: 'VariationList',
        component: VariationList,
        props: route => ({ params: route.query })
    },
    {
        path: 'unit',
        children: unit_routes
    },
    {
        path: ':pk',
        name: 'VariationUpdate',
        component: VariationChange,
        props: route => ({ params: route.query, pk: route.params.pk })
    },
]
export default variation_routes;