const UnitList = () => import("@/views/unit/UnitList.vue");
const UnitChange = () => import("@/views/unit/UnitChange.vue");


const unit_routes = [
    {
        path: '',
        name: 'UnitList',
        component: UnitList,
        props: route => ({ params: route.query })
    },
    {
        path: ':pk',
        name: 'UnitUpdate',
        component: UnitChange,
        props: route => ({ params: route.query, pk: route.params.pk })
    },
]
export default unit_routes;