const CustomerList = () => import("@/views/customer/CustomerList.vue");
const CustomerChange = () => import("@/views/customer/CustomerChange.vue");
import address_routes from "@/router/address";


const customer_routes = [
    {
        path: '',
        name: 'CustomerList',
        component: CustomerList,
        props: route => ({ params: route.query })
    },
    {
        path: 'add',
        name: 'CustomerCreate',
        component: CustomerChange,
        props: route => ({ params: route.query, add: true })
    },
    {
        path: 'address',
        children: address_routes
    },
    {
        path: ':pk',
        name: 'CustomerUpdate',
        component: CustomerChange,
        props: route => ({ params: route.query, pk: route.params.pk })
    },
]
export default customer_routes;