const SaleList = () => import("@/views/sale/SaleList.vue");
const SaleChange = () => import("@/views/sale/SaleChange.vue");


const sale_routes = [
    {
        path: '',
        name: 'SaleList',
        component: SaleList,
        props: route => ({params: route.query})
    },
    {
        path: 'add',
        name: 'SaleCreate',
        component: SaleChange,
        props: route => ({params: route.query, add: true})
    },
    {
        path: ':pk',
        name: 'SaleUpdate',
        component: SaleChange,
        props: route => ({params: route.query, pk: route.params.pk})
    },
]
export default sale_routes;