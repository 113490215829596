import {DefaultService} from "@/services/default";

export class AuthService extends DefaultService {
    static resourceURL = ''
    static resourceApp = 'users/'

    static login(data, params = {}, headers = {}) {
        return this.request('login/', 'POST', data, params, headers, null);
    }

    static register(data, params = {}, headers = {}) {
        return this.request('register/', 'POST', data, params, headers, null);
    }

    static verify(data, params = {}, headers = {}) {
        return this.request('verify/', 'POST', data, params, headers, null);
    }


}