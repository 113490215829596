const ProductList = () => import("@/views/product/ProductList.vue");
const ProductChange = () => import("@/views/product/ProductChange.vue");
import variation_routes from "@/router/variation";


const product_routes = [
    {
        path: '',
        name: 'ProductList',
        component: ProductList,
        props: route => ({ params: route.query })
    },
    {
        path: 'add',
        name: 'ProductCreate',
        component: ProductChange,
        props: route => ({ params: route.query, add: true })
    },
    {
        path: 'variation',
        children: variation_routes
    },
    {
        path: ':pk',
        name: 'ProductUpdate',
        component: ProductChange,
        props: route => ({ params: route.query, pk: route.params.pk })
    },
]
export default product_routes;