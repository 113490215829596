const OperationList = () => import("@/views/operation/OperationList.vue");
const OperationBooking = () => import("@/views/operation/OperationBooking.vue");
const OperationSale = () => import("@/views/operation/OperationSale.vue");


export const op_routes = [
    {
        path: '',
        name: 'OperationList',
        component: OperationList,
        props: route => ({params: route.query})
    }
]


export const booking_op_routes = [
    {
        path: ':pk',
        name: 'OperationBooking',
        component: OperationBooking,
        props: route => ({params: route.query, pk: route.params.pk})
    }
]

export const sale_op_routes = [
    {
        path: ':pk',
        name: 'OperationSale',
        component: OperationSale,
        props: route => ({params: route.query, pk: route.params.pk})
    },
]