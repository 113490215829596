const BookingList = () => import("@/views/booking/BookingList.vue");
const BookingChange = () => import("@/views/booking/BookingChange.vue");


const booking_routes = [
    {
        path: '',
        name: 'BookingList',
        component: BookingList,
        props: route => ({ params: route.query })
    },
    {
        path: 'add',
        name: 'BookingCreate',
        component: BookingChange,
        props: route => ({ params: route.query, add: true })
    },
    {
        path: ':pk',
        name: 'BookingUpdate',
        component: BookingChange,
        props: route => ({ params: route.query, pk: route.params.pk })
    },
]
export default booking_routes;