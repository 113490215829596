const UnitHistoryList = () => import("@/views/misc/unit-history/UnitHistoryList.vue");
const UnitHistoryChange = () => import("@/views/misc/unit-history/UnitHistoryChange.vue");

const unit_history_routes = [
    {
        path: '',
        name: 'UnitHistoryList',
        component: UnitHistoryList,
        props: route => ({params: route.query})
    },
    {
        path: ':pk',
        name: 'UnitHistoryUpdate',
        component: UnitHistoryChange,
        props: route => ({params: route.query, pk: route.params.pk})
    }
]
export default unit_history_routes;